import "./ToolsDwell.css";

import IconComplimentaryHouse from "~/img/complimentary/house.svg";
import IconComplimentaryLiving from "~/img/complimentary/living.svg";
import IconComplimentaryMoney from "~/img/complimentary/money.svg";
import IconComplimentaryPig from "~/img/complimentary/pig.svg";

export default function ToolsDwell() {
  return (
    <>
      <section class="tools-dwell">
        <div class="content-part" data-test="block-tools">
          <h2>
            Les outils <strong>pour préparer votre achat</strong>
          </h2>
          <div class="tools-cards" data-test="cards">
            <div class="tools-card" data-test="card">
              <IconComplimentaryLiving />
              <h3>PTZ</h3>
              <a href="/habiter/les-outils-pour-preparer-votre-achat/calcul-ptz.html">
                Calculez votre PTZ +
              </a>
            </div>
            <div class="tools-card" data-test="card">
              <IconComplimentaryPig />
              <h3>Crédit</h3>
              <a href="/calcul-capacite-d-emprunt.html">
                Calculez votre capacité d’emprunt
              </a>
            </div>
            <div class="tools-card" data-test="card">
              <IconComplimentaryMoney />
              <h3>Frais de notaire</h3>
              <a href="/habiter/les-outils-pour-preparer-votre-achat/calcul-frais-de-notaire.html">
                Calculez vos frais de notaire
              </a>
            </div>
            <div class="tools-card" data-test="card">
              <IconComplimentaryHouse />
              <h3>Emprunt</h3>
              <a href="/calcul-mensualites.html">
                Calculez vos futures mensualités
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
