import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";

import "./ContactDwell.css";

import IconCall from "~/img/icons/call.svg";
import IconForum from "~/img/icons/forum.svg";
import IconToday from "~/img/icons/today.svg";
import IconFace from "~/img/icons/face.svg";
import imgProxy from "~/utils/imgproxy";

export default function ContactDwell() {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  return (
    <>
      <section
        class="contact-dwell"
        ga-zone="contact"
        data-test="block-contact"
      >
        <div class="content-part">
          <h2>
            Vous avez des questions&nbsp;?
            <br />
            <strong>Nos experts vous répondent</strong>
          </h2>
          <div class="block-visio">
            <picture>
              <source
                media="(min-width: 375px)"
                srcset={imgProxy(
                  "/images/sales-office-visio-bg.jpg",
                  "size:768:421/resizing_type:fill",
                )}
              />
              <img
                src={imgProxy(
                  "/images/sales-office-visio-bg.jpg",
                  `size:327:180/quality:90/resizing_type:fill`,
                )}
                alt=""
                height="180"
                width="327"
                loading="lazy"
              />
            </picture>
            <div class="block-content">
              <h2>
                <i aria-hidden="true" class="cog-icon">
                  <IconFace />
                </i>
                Nous contacter
              </h2>
              <p>
                Tous nos conseillers sont disponibles pour vous accompagner dans
                votre projet et répondre à toutes vos questions, lors d’un
                rendez-vous téléphonique, en visioconférence ou sur l’un de nos
                espaces de vente.
              </p>
              <div class="buttons">
                <button
                  type="button"
                  class="btn"
                  data-test="cta-callback"
                  onClick={(e) => {
                    sendShowEvent(`callback`, e);
                    openModalForm!("callback");
                    setModalFormHeadline!("Être appelé");
                  }}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconCall />
                  </i>
                  Être appelé
                </button>
                <button
                  type="button"
                  class="btn"
                  data-test="cta-advisor-question"
                  onClick={(e) => {
                    sendShowEvent("contact-us", e);
                    openModalForm!("advisor", {
                      defaultValues: { subject: "question" },
                    });
                    setModalFormHeadline!("Poser une question");
                  }}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconForum />
                  </i>
                  Poser une question
                </button>
                <button
                  type="button"
                  class="btn"
                  data-test="cta-advisor-appointment"
                  onClick={(e) => {
                    sendShowEvent("advisor", e);
                    openModalForm!("advisor", {
                      defaultValues: { subject: "rdv" },
                    });
                    setModalFormHeadline!("Prendre un rendez-vous");
                  }}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconToday />
                  </i>
                  Prendre un rendez-vous
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
