import { AutopromosRenderer } from "~/components/shared/Autopromos/AutopromosRenderer";
import FrontArticles from "~/components/Home/Components/FrontArticles";
import FirstScreenDwell from "~/components/Home/Components/FirstScreenDwell";
import AboutDwell from "~/components/Home/Components/AboutDwell";
import HelpDwell from "~/components/Home/Components/HelpDwell";

import type { HomeDataDwell } from "~/types/drupal_jsonapi";

import "./FrontPage.css";
import "./FrontPageDwell.css";
import ToolsDwell from "~/components/Home/Components/ToolsDwell";
import ContactDwell from "~/components/Home/Components/ContactDwell";
import SEODwell from "~/components/Home/Components/SEODwell";

export default function FrontPageDwell(props: { data: HomeDataDwell }) {
  return (
    <>
      <article class="front-page front-page-dwell">
        <div class="background-color">
          <FirstScreenDwell />
          <AboutDwell />
          <HelpDwell />
        </div>

        {/* AUTOPROMO AREAS */}
        <section class="front-areas-container" data-ga-zone="areas">
          <AutopromosRenderer text="[promo-areas-search:||vat_inc,vat_inc_reduced,vat_inc_brs]" />
        </section>

        {/* TOOLS */}
        <ToolsDwell />

        {/* ARTICLES */}
        <FrontArticles
          articles={props.data.articles}
          title="Nos conseils <strong>pour bien acheter</strong>"
        />

        {/* CONTACT */}
        <ContactDwell />

        {/* SEO */}
        <SEODwell />
      </article>
    </>
  );
}
