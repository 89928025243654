import imgProxy from "~/utils/imgproxy";

import "./AboutDwell.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function AboutDwell() {
  return (
    <>
      <section class="about-dwell">
        <div class="content-part" data-test="contents">
          <h2>
            <strong>Tout savoir</strong> sur l’achat immobilier neuf
          </h2>
          <div class="about-cards" data-test="cards">
            <div class="about-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/about-01.jpg",
                    "size:327:143/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="143"
                />
              </picture>
              <h3>
                <a href="/habiter/acheter-dans-le-neuf/mon-premier-achat">
                  Mon premier achat
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </h3>
              <ul>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/mon-premier-achat/aides-jeune-proprietaire/">
                    Aides jeune propriétaire
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/mon-premier-achat/checklist-achat-appartement/">
                    Checklist avant d’acheter
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/mon-premier-achat/etapes-achat-immobilier/">
                    Étapes d’un achat immobilier
                  </a>
                </li>
              </ul>
            </div>
            <div class="about-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/about-02.jpg",
                    "size:327:143/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="143"
                />
              </picture>
              <h3>
                <a href="/habiter/acheter-dans-le-neuf/nouvel-achat-immobilier">
                  Nouvel achat immobilier
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </h3>
              <ul>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/nouvel-achat-immobilier/debuter-nouvelle-vie/">
                    Débuter une nouvelle vie
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/nouvel-achat-immobilier/logement-plus-grand/">
                    Un logement plus grand
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/nouvel-achat-immobilier/revendre-bien/">
                    Revendre son bien
                  </a>
                </li>
              </ul>
            </div>
            <div class="about-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/about-03.jpg",
                    "size:327:143/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="143"
                />
              </picture>
              <h3>
                <a href="/habiter/acheter-dans-le-neuf/avantages-immobilier-neuf/">
                  Les avantages du neuf
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </h3>
              <ul>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/avantages-immobilier-neuf/frais-notaire-reduits/">
                    Frais de notaire réduits
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/avantages-immobilier-neuf/livraison-cle-main/">
                    Livraison clé en main
                  </a>
                </li>
                <li>
                  <a href="/habiter/acheter-dans-le-neuf/avantages-immobilier-neuf/personnaliser-logement/">
                    Personnaliser son logement
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
