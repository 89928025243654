import imgProxy from "~/utils/imgproxy";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { createMemo } from "solid-js";

import "./FirstScreenDwell.css";

export default function FirstScreenDwell() {
  const [viewPortState] = useViewportSizeContext();

  const defaultImg = createMemo(() => {
    return viewPortState.viewPortIsMoreThan768
      ? "/images/front-page-dwell/home-dwell-bg.jpg"
      : "/images/front-page-dwell/home-dwell-mobile-bg.jpg";
  });

  return (
    <>
      <section
        class="first-screen"
        data-test="first-screen"
        data-ga-zone="first-screen"
      >
        <picture data-test="main-image">
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/front-page-dwell/home-dwell-bg.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/front-page-dwell/home-dwell-bg.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/front-page-dwell/home-dwell-bg.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/front-page-dwell/home-dwell-mobile-bg.jpg",
              "size:750:1334/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/front-page-dwell/home-dwell-mobile-bg.jpg",
              "size:375:667/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              defaultImg()!,
              `size:${viewPortState.viewPortIsMoreThan768 ? "1366:768" : "375:667"}/resizing_type:fill`,
            )}
            alt=""
            height={viewPortState.viewPortIsMoreThan768 ? 768 : 667}
            width={viewPortState.viewPortIsMoreThan768 ? 1366 : 375}
          />
        </picture>
        <div class="content-part">
          <h1>
            Achetez votre <strong>résidence principale</strong> dans le neuf
          </h1>
        </div>
      </section>
    </>
  );
}
