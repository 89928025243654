import IconArrowForward from "~/img/icons/arrow_forward.svg";
import imgProxy from "~/utils/imgproxy";

import "./HelpDwell.css";

export default function HelpDwell() {
  return (
    <>
      <section class="help-dwell">
        <div class="content-part" data-test="help">
          <h2>
            <strong>Les aides</strong> à l’achat
          </h2>
          <div class="help-cards" data-test="cards">
            <div class="help-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/help-01.jpg",
                    "size:327:184/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="184"
                />
              </picture>
              <div class="help-card-content">
                <h3>Le Prêt à Taux Zéro (PTZ)</h3>
                <p>
                  <strong>
                    Envie d'une aide financière sans intérêt pour votre première
                    acquisition&nbsp;?
                  </strong>
                  <br />
                  Financez une partie de votre achat immobilier dans le neuf
                  sans frais d'intérêt. Découvrez dès maintenant les critères
                  d'éligibilité et les conditions pour accéder à ce soutien
                  financier avantageux&nbsp;!
                </p>
                <a href="/habiter/les-aides-pour-acheter-dans-le-neuf/le-pret-a-taux-zero.html">
                  <span>Je découvre les conditions</span>
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </div>
            </div>
            <div class="help-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/help-02.jpg",
                    "size:327:184/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="184"
                />
              </picture>
              <div class="help-card-content">
                <h3>La TVA réduite</h3>
                <p>
                  <strong>
                    Envie de réduire le coût de votre acquisition
                    immobilière&nbsp;?
                  </strong>
                  <br />
                  Bénéficiez d'un taux de TVA avantageux pour l'achat de votre
                  résidence principale. Découvrez comment bénéficier de cette
                  réduction fiscale et explorez les détails de cette offre dès
                  maintenant.
                </p>
                <a href="/habiter/les-aides-pour-acheter-dans-le-neuf/une-tva-reduite-a-5-5.html">
                  <span>J’accède aux avantages</span>
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </div>
            </div>
            <div class="help-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/help-03.jpg",
                    "size:327:184/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="184"
                />
              </picture>
              <div class="help-card-content">
                <h3>Le Bail Réel Solidaire</h3>
                <p>
                  <strong>
                    Acheter un logement neuf à un prix abordable&nbsp;?
                  </strong>
                  <br />
                  C'est possible avec le Bail Réel Solidaire&nbsp;! Découvrez
                  comment accéder à la propriété en maîtrisant vos dépenses
                  grâce à ce dispositif original. Explorez les détails du Bail
                  Réel Solidaire pour concrétiser votre projet immobilier&nbsp;!
                </p>
                <a href="/habiter/les-aides-pour-acheter-dans-le-neuf/le-bail-reel-solidaire.html">
                  <span>J’économise dès maintenant</span>
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </div>
            </div>
            <div class="help-card" data-test="card">
              <picture>
                <img
                  src={imgProxy(
                    "/images/front-page-dwell/help-04.jpg",
                    "size:327:184/resizing_type:fill",
                  )}
                  alt=""
                  width="327"
                  height="184"
                />
              </picture>
              <div class="help-card-content">
                <h3>L’accession à prix maitrisé</h3>
                <p>
                  <strong>
                    Vous rêvez de devenir propriétaire à un coût
                    avantageux&nbsp;?
                  </strong>
                  <br />
                  L’accession à prix maîtrisé vous ouvre cette
                  possibilité&nbsp;! Découvrez cette solution permettant
                  d’acquérir votre résidence principale à des conditions
                  financières favorables.
                </p>
                <a href="/habiter/les-aides-pour-acheter-dans-le-neuf/l-accession-a-prix-maitrise.html">
                  <span>J’optimise mon achat</span>
                  <i aria-hidden="true" class="cog-icon">
                    <IconArrowForward />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
