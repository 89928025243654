import { createSignal } from "solid-js";

import "~/components/shared/SEOBlock.css";
import "./SEODwell.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function SEODwell() {
  const [isExpanded, setIsExpanded] = createSignal(false);
  return (
    <>
      <section class="seo-dwell seo-block" data-test="block-seo">
        <div class="content-part">
          <h1>Faire un achat immobilier dans le neuf</h1>

          <div
            class="seo-text"
            classList={{ expanded: isExpanded() }}
            data-test="seo-text"
          >
            <div class="inner">
              <div class="text">
                <p>
                  Vous souhaitez accéder à la propriété&nbsp;? Dans ce cas,
                  investissez dans le neuf, car c’est l’opportunité de profiter
                  de très nombreux avantages financiers, fiscaux et sur le bien
                  immobilier lui-même.
                </p>
                <p>
                  Cependant, ce type d’acquisition dispose de son propre
                  fonctionnement.
                </p>
                <p>
                  Nous vous présentons les avantages et les étapes d’un achat
                  neuf.
                </p>
                <h2>Les avantages d’un achat immobilier neuf</h2>
                <h3>
                  Un appartement neuf qui répond à toutes vos attentes de
                  propriétaire
                </h3>
                <p>
                  Dans le cadre d’une acquisition dans le neuf, en tant que
                  propriétaire, vous ne pouvez qu’être comblé. En effet&nbsp;:
                </p>
                <ul>
                  <li>
                    Vous achetez un bien sur plans, que vous pouvez concevoir
                    sur-mesure et adapter selon vos besoins et votre mode de
                    vie, sans faire de compromis comme vous le devriez avec un
                    achat ancien.
                  </li>
                  <li>
                    Votre logement profite des dernières normes thermiques, en
                    faisant ainsi un bien écologique présentant une excellente
                    performance énergétique qui se fera ressentir sur votre
                    facture.
                  </li>
                  <li>
                    Vous réalisez un investissement serein, car vous avez la
                    certitude de ne pas devoir ajouter des frais de travaux
                    surprises, que ce soit au titre des parties privatives ou
                    des parties communes.
                  </li>
                  <li>
                    Vous acquérez un bien qui est couvert pour tous ses
                    dysfonctionnements, défauts et malfaçons, grâce aux diverses
                    garanties d’assurances relatives aux biens VEFA.
                  </li>
                </ul>
                <h3>Un investissement permettant des économies</h3>
                <p>
                  L’achat d’un appartement neuf ou d’une maison neuve permet de
                  profiter d’avantages financiers certains. En
                  l’occurrence&nbsp;:
                </p>
                <ul>
                  <li>
                    Pour les biens neufs, les frais de notaire sont limités à
                    2-3&nbsp;% au lieu de 7-8&nbsp;%.
                  </li>
                  <li>
                    Pour l’achat d’une construction neuve dans une zone ANRU ou
                    QPV, vous pouvez bénéficier d’une TVA de 5.5&nbsp;% contre
                    les 20 &nbsp;% habituels, c’est donc l’opportunité
                    d’économiser 14,5&nbsp;%.
                  </li>
                  <li>
                    Vous profitez d’une exonération de la taxe foncière durant
                    les deux premières années suivant l’acquisition. Celle-ci
                    peut être totale ou partielle, et dépend de la politique
                    mise en place par la ville.
                  </li>
                </ul>
                <p>
                  Pour les primo-accédants souhaitant acheter leur résidence
                  principale, il est possible de bénéficier du prêt à taux zéro
                  PTZ, qui permet de financer partiellement l’achat, sans aucun
                  frais emprunt, et qui est soumis au respect de certaines
                  conditions de revenus, en fonction de la zone géographique.
                </p>
                <p>
                  Acheter dans le neuf ne présente donc que des avantages et ce,
                  qu’importe son statut.
                </p>
                <h2>
                  Votre projet immobilier&nbsp;: les étapes d’un achat
                  immobilier neuf
                </h2>
                <p>
                  Acheter un appartement neuf ou une maison neuve ne se déroule
                  pas de la même façon que pour l’achat d’un bien ancien.
                </p>
                <p>En effet, les étapes sont différentes&nbsp;:</p>
                <ol>
                  <li>
                    Tout d’abord, vous allez signer un contrat de réservation et
                    verser un dépôt de garantie. Le montant de celui-ci est
                    strictement encadré par la loi, il n’est donc pas possible
                    de payer plus que les pourcentages prédéterminés.
                  </li>
                  <li>
                    Ensuite, vous aurez une période de rétractation de 10 jours,
                    pendant laquelle vous pourrez revenir sur votre décision,
                    sans avoir à justifier de quoi que ce soit, et sans pénalité
                    financière, vous récupérerez donc l’intégralité du dépôt de
                    garantie versé.
                  </li>
                  <li>
                    Puis, vous allez entreprendre les démarches relatives à
                    l’obtention de votre prêt en vous rapprochant de différentes
                    banques. Vous recevrez ainsi une offre préalable de la
                    banque prête à vous suivre dans votre projet immobilier.
                  </li>
                  <li>
                    Lorsque vous avez l’accord officiel de votre financement,
                    vous aurez rendez-vous chez le notaire pour signer l’acte
                    authentique de vente, et devenez ainsi officiellement
                    propriétaire.
                  </li>
                  <li>
                    Au fur et à mesure de l’avancée des travaux, vous versez des
                    appels de fonds au promoteur immobilier, qui sont également
                    encadrés par la loi&nbsp;: 35&nbsp;% lors de l’achèvement
                    des fondations, 70&nbsp;% à la mise hors d’eau et 95&nbsp;%
                    au moment de l’achèvement.
                  </li>
                  <li>
                    Une fois les travaux terminés, vous allez signer un
                    procès-verbal de livraison, mentionner vos réserves s’il y
                    en a, et arriver enfin le moment de la remise des clés. Vous
                    pouvez donc emménager.
                  </li>
                </ol>
                <h2>
                  Pourquoi passer par Cogedim pour acheter un appartement
                  neuf&nbsp;?
                </h2>
                <p>
                  Cogedim est un promoteur immobilier spécialisé dans le neuf,
                  qui propose un large choix d’annonces pour des programmes
                  immobiliers neufs, et vous donne accès à des logements
                  différents, atypiques, d’une grande qualité et situés dans des
                  quartiers recherchés.
                </p>
                <p>
                  L’achat d’un appartement sur plans faisant partie d’un
                  programme immobilier neuf peut s’avérer être très stressant
                  (et si la construction n’aboutit jamais ou avec du retard,
                  etc.) et passer par un spécialiste reconnu sur le marché,
                  c’est l’assurance de passer par un professionnel sérieux et
                  que votre projet se déroulera sans accroc.
                </p>
                <p>
                  Enfin, Cogedim ne vous vend pas seulement un bien immobilier
                  neuf, il vous accompagne durant cette expérience, vous propose
                  des biens en avant-première, vous conseille pour le
                  financement le mieux adapté à votre budget et à votre projet,
                  grâce à un accompagnement personnalisé.
                </p>
                <h2>FAQ - Foire Aux Questions</h2>
                <h3>
                  Quels sont les pièges à éviter lors d’un achat
                  immobilier&nbsp;?
                </h3>
                <p>
                  La réussite d’un achat immobilier repose sur une vigilance
                  accrue à chaque étape. Vérifiez minutieusement les{" "}
                  <strong>normes environnementales</strong> du bien, sa
                  localisation par rapport aux transports en commun et la
                  solidité financière du promoteur. Examinez attentivement la
                  garantie décennale et les espaces extérieurs. Calculez tous
                  les frais annexes au-delà du prix d’achat, notamment en état
                  futur d’achèvement. Un accompagnement professionnel permet
                  d’anticiper ces points critiques.
                </p>
                <h3>
                  Comment se déroule l’achat d’un bien immobilier neuf&nbsp;?
                </h3>
                <p>
                  L’acquisition dans le neuf débute par une réservation auprès
                  du promoteur suivie d’un délai légal de réflexion de 10 jours.
                  La signature du contrat de vente définitif chez le notaire
                  marque le lancement des travaux. Les versements s’échelonnent
                  selon l’avancement du chantier. Une visite de pré-livraison
                  permet de vérifier la conformité du logement avant la remise
                  des clés.
                </p>
                <h3>
                  Quels sont les avantages fiscaux de l’immobilier neuf&nbsp;?
                </h3>
                <p>
                  L’achat d’un logement neuf offre des{" "}
                  <strong>réductions fiscales significatives</strong> grâce aux
                  frais de notaire réduits à 2-3&nbsp;%, une exonération de taxe
                  foncière pendant 2 ans et une TVA à taux réduit sous
                  conditions.
                </p>
                <h3>Faut-il privilégier le neuf ou l’ancien&nbsp;?</h3>
                <p>
                  Le choix du <strong>neuf</strong> s’impose pour les acquéreurs
                  recherchant des logements aux normes environnementales
                  actuelles avec des économies d’énergie maximales. La
                  construction moderne garantit un confort optimal et une
                  maintenance réduite pendant plusieurs années. Le neuf devient
                  particulièrement pertinent dans les grandes villes pour les
                  budgets permettant d’absorber un prix au m² supérieur par
                  rapport à l’ancien.
                </p>
                <h3>Est-ce une bonne idée d’acheter dans le neuf&nbsp;?</h3>
                <p>
                  Un logement neuf s’avère judicieux pour les acquéreurs
                  souhaitant personnaliser leur habitat et bénéficier d’une
                  meilleure isolation phonique. La construction moderne assure
                  une qualité de vie supérieure avec des matériaux performants
                  et des agencements optimisés. Le neuf répond aux attentes des
                  familles cherchant un cadre résidentiel paisible et des
                  espaces adaptés à leurs besoins, notamment dans les zones en
                  développement près des transports en commun.
                </p>
              </div>
            </div>
            <div class="expand-button">
              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded())}
                aria-label="Découvrir comment faire un achat immobilier dans le neuf"
                data-test="btn-expand-seo"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
